import { securedWrap } from '@mop/shared/utils/securedWrap';
import { throttle } from '@mop/shared/utils/util';
import { localStorageSet, localStorageGet, localStorageRemove } from '@mop/shared/utils/localStorage';
import type {
  RegistrationFormSections,
  RegistrationFormData,
  StoreAddress,
  StoreDeliveryAddress,
  AdditionalDivisionListItem,
} from '@/types/registrationData';

type FormStep = {
  previousComponent: string;
  component?: string;
  nextComponent: string;
  title: string;
  subTitle: string;
};

const storeDeliveryAddress: StoreDeliveryAddress = {
  companyName: '',
  companyCO: '',
  street: '',
  houseNumber: '',
  additional: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  phoneNumber: '',
  comment: '',
  isCentralWarehouse: false,
};

const storeDivisionItem: AdditionalDivisionListItem = {
  __state: {
    edit: true,
    isNew: true,
  },
  division: '',
  department: '',
  customerType: '',
  distributionChannel: '',
  discount: '',
};

const storeAddress: StoreAddress = {
  __state: {
    edit: true,
    isNew: true,
  },
  companyName: '',
  companyCO: '',
  street: '',
  houseNumber: '',
  additional: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  phoneNumber: '',
  deliveryEmailList: [''],
  comment: '',
  isCentralWarehouse: false,
  deliveryAddressType: 'same',
  deliveryAddress: storeDeliveryAddress,
  additionalDivisionList: [],
  additionalShippingConditions: '',
  additionalDeliveryConditions: '',
  additionalDeliveryConditionsPlace: '',
};

const defaultObj: RegistrationFormData = {
  __state: {},
  isComplete: false,
  salesAgent: {
    id: 0,
    firstName: '',
    lastName: '',
  },
  contactPerson: {
    fullName: '',
    role: '',
    email: '',
  },
  companyInformation: {
    companyName: '',
    legalForm: '',
    owner: '',
    vatNumber: '',
    additionalPaymentTerms: '',
    additionalPriceLists: '',
    additionalSpecialAgreements: '',
    additionalReturnConditions: '',
    additionalLogisticServices: '',
    isPreOrderBlockAllowed: false,
  },
  companyAddress: {
    street: '',
    houseNumber: '',
    additional: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    phoneNumber: '',
    emailList: [''],
    orderConfirmationEmailList: [''],
  },
  invoiceAddress: {
    companyName: '',
    companyCO: '',
    street: '',
    houseNumber: '',
    additional: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    phoneNumber: '',
    emailList: [''],
  },
  storeAddress: {
    list: [storeAddress],
  },
  paymentDetails: {
    type: 'self',
    self: {
      isDirectDebit: false,
      iban: '',
      bic: '',
      email: '',
    },
    payer: {
      name: '',
      accountNumber: '',
      comment: '',
    },
  },
  ediDetails: {
    isSelected: false,
    fullName: '',
    email: '',
  },
  autoReplenishDetails: {
    isSelected: false,
    fullName: '',
    email: '',
  },
  b2bLogin: {
    email: '',
  },
  terms: {
    agreed: false,
  },
};

type LoadingState = {
  loading: boolean;
  loadingSalesAgent: boolean;
};

type AgentItemResponse = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
};

type SalesAgentListItem = {
  value: number;
  text: string;
  item: AgentItemResponse;
};

type FormStorage = {
  composable: {
    getFormSection<K extends RegistrationFormSections>(key: K): RegistrationFormData[K];
    addNewStoreAddress(): void;
    addNewStoreDivision(storeAddress: StoreAddress): void;
    sendRegistration(): Promise<boolean>;
    completeRegistration(): Promise<boolean>;
    initRegistration(): void;
    initCompleteRegistration(id: string): Promise<boolean>;
    initFinishedRegistration(id: string): Promise<boolean>;
    initSalesAgents(): void;
    dataRef: Ref<RegistrationFormData | undefined>;
    stepDataRef: Ref<FormStep>;
    loadingRef: Ref<LoadingState>;
    salesAgentListRef: Ref<SalesAgentListItem[]>;
    isFormCompletedRef: Ref<boolean>;
  };
};

export default function useRegistrationForm() {
  const { $apiAws, $mopI18n, $config, $cookie } = useNuxtApp();
  const isDebugCookieEnabled = Boolean($cookie.get(COOKIE.DEBUG));
  const storage = initStorage<FormStorage>('useRegistrationForm');
  const storedComposable = storage.get('composable');
  if (storedComposable) {
    return storedComposable;
  }
  const registrationIdRef = ref('');
  const KEY_FORM = 'form';
  const dataRef = ref<RegistrationFormData>();
  const loadingRef = ref<LoadingState>({ loading: false, loadingSalesAgent: false });
  const salesAgentListRef = ref<SalesAgentListItem[]>([]);
  const isFormCompletedRef = computed(() => {
    return Boolean(!isDebugCookieEnabled && dataRef.value?.isComplete);
  });

  const stepDataRef = ref<FormStep>({
    previousComponent: '',
    component: 'FormRegisterSectionSalesAgent',
    nextComponent: 'FormRegisterSectionContactPerson',
    title: '',
    subTitle: '',
  });

  function watchFormChanges() {
    // Check later if throttle wait time requires tweaking. No need to save after every key push
    watch(dataRef, throttle(storeDataInStorage, 1000), { deep: true });
  }

  function getDataFromStorage(): RegistrationFormData | undefined {
    try {
      const existingData = localStorageGet(getStorageKey());
      if (existingData) {
        return JSON.parse(existingData);
      }
    } catch (error) {
      // do nothing
    }
  }

  function storeDataInStorage() {
    localStorageSet(getStorageKey(), JSON.stringify(dataRef.value));
  }

  function getFormSection<K extends RegistrationFormSections>(key: K): RegistrationFormData[K] {
    return dataRef.value![key];
  }

  function addNewStoreAddress() {
    dataRef.value!.storeAddress.list!.push(structuredClone(storeAddress));
  }

  function addNewStoreDivision(storeAddress: StoreAddress) {
    storeAddress.additionalDivisionList.push(structuredClone(storeDivisionItem));
  }

  function getStorageKey() {
    let key = KEY_FORM;
    if (registrationIdRef.value) {
      key += '-' + encodeURIComponent(registrationIdRef.value);
    }
    return key;
  }

  function getSalesEmails() {
    const country = dataRef.value!.companyAddress.country;
    const semicolonSeparatedList = ['DE', 'AT', 'CH'].includes(country)
      ? $config.public.RECIPIENT_LIST_DACH
      : $config.public.RECIPIENT_LIST_INT;
    return semicolonSeparatedList.split(';');
  }

  async function sendRegistration(): Promise<boolean> {
    loadingRef.value.loading = true;
    dataRef.value!.isComplete = false;
    const result = await $apiAws.sendRegistration({
      recipientsCcStep1: [],
      locale: $mopI18n.locale,
      data: dataRef.value,
    });
    loadingRef.value.loading = false;
    if (result.error || result.data === undefined) {
      return false;
    }

    localStorageRemove(getStorageKey());
    dataRef.value = structuredClone(defaultObj);
    return true;
  }

  async function completeRegistration(): Promise<boolean> {
    loadingRef.value.loading = true;
    dataRef.value!.isComplete = true;
    const result = await $apiAws.completeRegistration(
      {
        recipientsStep2: getSalesEmails(),
        locale: $mopI18n.locale,
        data: dataRef.value,
      },
      registrationIdRef.value,
    );
    loadingRef.value.loading = false;
    if (result.error || result.data === undefined) {
      return false;
    }
    localStorageRemove(getStorageKey());
    return true;
  }

  // For step1 - customer is registering
  function initRegistration() {
    dataRef.value = getDataFromStorage() || structuredClone(defaultObj);
    watchFormChanges();
  }

  // For step2 - sales agent is completing registration
  async function initCompleteRegistration(id: string) {
    loadingRef.value.loading = true;
    registrationIdRef.value = id;
    watchFormChanges();
    const existingRegistration = getDataFromStorage();
    if (existingRegistration) {
      dataRef.value = existingRegistration;
      loadingRef.value.loading = false;
      return true;
    }
    const result = await $apiAws.getRegistration(id);
    if (!result.error && result.data) {
      dataRef.value = result.data;
      storeDataInStorage();
    }
    loadingRef.value.loading = false;
    return true;
  }

  // For step2 - final data (always fetch fresh from api)
  async function initFinishedRegistration(id: string) {
    loadingRef.value.loading = true;
    registrationIdRef.value = id;
    const result = await $apiAws.getRegistration(id);
    if (!result.error && result.data) {
      dataRef.value = result.data;
    }
    loadingRef.value.loading = false;
    return true;
  }

  async function initSalesAgents() {
    loadingRef.value.loadingSalesAgent = true;
    const result = await $apiAws.getSalesAgents();
    salesAgentListRef.value = result.data
      .map((item: AgentItemResponse) => {
        return {
          value: item.id,
          text: `${item.lastname} ${item.firstname}`,
          item,
        };
      })
      .sort((a: SalesAgentListItem, b: SalesAgentListItem) => (a.text > b.text ? 1 : -1));

    loadingRef.value.loadingSalesAgent = false;
    return true;
  }

  return storage.saveAndGet(
    'composable',
    securedWrap({
      getFormSection,
      addNewStoreAddress,
      addNewStoreDivision,
      dataRef,
      stepDataRef,
      loadingRef,
      salesAgentListRef,
      sendRegistration,
      completeRegistration,
      initRegistration,
      initCompleteRegistration,
      initFinishedRegistration,
      initSalesAgents,
      isFormCompletedRef,
    }),
  );
}
